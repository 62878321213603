import React from "react";
import Container from "@material-ui/core/Container";

import Link from "../link";

import * as styles from "./styles.module.scss";

export default class SpotlioSocket extends React.Component {
  render() {
    const config = {
      image:
        "https://ik.imagekit.io/spotlio/spotlio/PoweredBySpotlio_o14pmR2mb.png",
      link: "https://www.spotlio.com/",
      label: "Powered by SPOTLIO",
    };

    return (
      <Container className={styles.socketWrapper} maxWidth={false}>
        <Container maxWidth={"lg"}>
          <div className={styles.socket}>
            <Link url={config.link} title={config.label} target="_blank">
              <img
                width={81}
                height={40}
                src={config.image}
                alt={config.label}
              />
            </Link>
          </div>
        </Container>
      </Container>
    );
  }
}
