import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Container, Typography, Button } from "../../components/utils";
import Icon from "../../components/icons";
import CategoryMenu from "../../components/categoryMenu";
import WeatherBanner from "../../components/weatherBanner";
import SnowConditionsBanner from "../../components/snowConditionsBanner";
import LanguageWidget from "../../components/languageWidget";
import Currency from "../../components/currency";
import Image from "../../components/image";
import LoginModal from "../../components/session/loginModal";
import Link from "../../components/link";
import CrossMenu from "../../components/animations/crossMenu";
import useTopbar from "./logic";
import { TopbarProps } from "./types";
import * as styles from "./styles.module.scss";
import { DEFAULT_LANGUAGE } from "../../utils/definitions";

const Topbar = (props: TopbarProps) => {
  const {
    config,
    scrolled,
    showLanguages,
    showCart,
    showProfile,
    productsCurrency,
    productsTotalPrice,
    productsLength,
    logo,
    loginModal,
    openLoginModal,
    closeLoginModal,
    logged,
    defaultEmail,
    selectedLanguage,
    mainMenuOpen,
    setMainMenuOpen,
    languagesMenuOpen,
    setLanguagesMenuOpen,
  } = useTopbar(props);

  const [configState, setConfigState] = useState();

  useEffect(() => {
    setConfigState(config);
  }, []);

  const languageToRender = selectedLanguage || DEFAULT_LANGUAGE.toLowerCase();

  return (
    <Container
      className={`${styles.topbar} ${scrolled ? styles.scrolled : ""}`}
      component={"nav"}
      maxWidth={false}
    >
      <Container className={styles.topbarContainer} maxWidth={"lg"}>
        <div className={styles.category}>
          <div
            onClick={() => setMainMenuOpen(!mainMenuOpen)}
            className={styles.menuWrapper}
          >
            <CrossMenu size={"48px"} isMenuActive={mainMenuOpen} />
          </div>
          <CategoryMenu
            type={"menu"}
            menus={configState && configState.menu}
            visible={mainMenuOpen}
            setVisible={setMainMenuOpen}
          >
            {showLanguages && (
              <LanguageWidget selectedLanguage={languageToRender} />
            )}
          </CategoryMenu>
        </div>
        <Link url="/" className={styles.logoLink}>
          <Image
            type="thumbnail"
            noFluid={false}
            image={configState && configState.logo}
            alt={configState && configState.logoAlternativeText}
            style={{ width: logo.width, height: logo.height }}
            imgStyle={{ width: logo.width, height: logo.height }}
          />
        </Link>
        <div className={styles.widgets}>
          {showLanguages && (
            <div className={styles.language}>
              <Button
                onClick={() => setLanguagesMenuOpen(!languagesMenuOpen)}
                className={styles.selectedLanguage}
              >
                <div className={styles.languageWrapper}>
                  <Typography component="span" variant="h500-heavy">
                    {languageToRender.toUpperCase()}
                  </Typography>
                  <Icon icon="ChevronDown" size="Small" />
                </div>
              </Button>
              <CategoryMenu type="language" visible={languagesMenuOpen}>
                <LanguageWidget selectedLanguage={languageToRender} />
              </CategoryMenu>
            </div>
          )}
          {showProfile && (
            <div className={styles.userWrapper}>
              <Button
                className={styles.user}
                onClick={() => openLoginModal()}
                ariaLabel={logged ? "Go to profile screen" : "Login modal"}
              >
                <Icon icon="User" size="Small" />
                {logged && (
                  <Icon icon="Check" size="S" className={styles.check} />
                )}
              </Button>
            </div>
          )}
          {showCart && (
            <div className={styles.shoppingCartWrapper}>
              <Button
                className={styles.shoppingCart}
                contentClassName={styles.shoppingCartContent}
                onClick={() => navigate("/cart")}
              >
                <div className={styles.cartIcon}>
                  <Icon icon="ShoppingCart" size="Small" />
                  <Typography
                    component="span"
                    variant="h100-heavy"
                    className={styles.badge}
                  >
                    {productsLength}
                  </Typography>
                </div>
                {productsLength > 0 && (
                  <Currency
                    template="topbar"
                    currency={productsCurrency}
                    price={productsTotalPrice}
                    className={styles.bundlePrice}
                  />
                )}
              </Button>
            </div>
          )}
        </div>
      </Container>
      {loginModal && (
        <LoginModal
          defaultEmail={defaultEmail}
          closeFn={() => closeLoginModal()}
          loginCallback={() => navigate("/my-account")}
        />
      )}
    </Container>
  );
};

export default Topbar;
