import React from "react";
import { Typography } from "../utils";

import { CurrencyProps } from "./types";
import {
  parseCurrencyCodetoSign,
  getDecimalPart,
  prepareIntegerPart,
  getTemplate,
  getCurrencyChar,
  getCurrencyPosition,
  shouldDecimalsBeDisplayed,
} from "./logic";
import { getMainConfig } from "../../utils/getConfig";

const Currency = ({
  currency,
  price,
  char = ".",
  template,
  className,
  displayDecimals = getMainConfig({ field: "showDecimals" }),
}: CurrencyProps) => {
  const currencyPosition = getCurrencyPosition();
  const parsedCurency = parseCurrencyCodetoSign(currency);
  const intPart = prepareIntegerPart(price, getCurrencyChar());
  const decimalPart = getDecimalPart(price);
  const temp = getTemplate(template, currencyPosition);

  return (
    <Typography
      component="span"
      variant={temp.integerVariant}
      className={className}
    >
      {currencyPosition === "left" && (
        <Typography
          style={{ paddingRight: "0.2rem" }}
          component="span"
          variant={
            temp.currencyVariant ? temp.currencyVariant : temp.decimalVariant
          }
        >
          {parsedCurency}
        </Typography>
      )}
      {intPart}
      {shouldDecimalsBeDisplayed(displayDecimals) && (
        <Typography component="span" variant={temp.decimalVariant}>
          {decimalPart}
        </Typography>
      )}
      {currencyPosition === "right" && (
        <Typography
          style={{ paddingLeft: "0.2rem" }}
          component="span"
          variant={
            temp.currencyVariant ? temp.currencyVariant : temp.decimalVariant
          }
        >
          {parsedCurency}
        </Typography>
      )}
    </Typography>
  );
};

export default Currency;
