import { useState, useEffect } from "react";
import { navigate } from "gatsby";

import { size } from "../../constants";
import { getLocale, getSession } from "../../utils/localStorage";
import QueryStringRetriever from "../../utils/queryStringRetriever";
import { EMAIL_REGEX } from "../../components/forms/input/regex";

import { TopbarProps, CategoryValues } from "./types";
import { getTopbarConfig } from "../../utils/getConfig";

const useTopbar = (props: TopbarProps) => {
  const config = getTopbarConfig({ locale: getLocale() });
  const [defaultEmail, setDefaultEmail] = useState("");
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [languagesMenuOpen, setLanguagesMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [session, setSession] = useState(getSession());
  const [loginModal, setLoginModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();

  let logo = { height: 0, width: 0 };
  if (typeof window !== "undefined" && window.innerWidth > 991) {
    logo.height = parseFloat(config.logoDesktopMaxHeight);
    logo.width = (config.logo.width / config.logo.height) * logo.height;
  } else {
    logo.height = parseFloat(config.logoMobileMaxHeight);
    logo.width = (config.logo.width / config.logo.height) * logo.height;
  }

  let showLanguages = config.showLanguages;
  let showCart = config.showCart;
  let showProfile = config.showProfile;

  useEffect(() => {
    let QS = QueryStringRetriever;
    if (QS().email && EMAIL_REGEX.test(QS().email)) {
      openLoginModal();
      setDefaultEmail(QS().email);
    }
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("setSession", rerenderCart);
    return () => {
      window.removeEventListener("setSession", rerenderCart);
    };
  });

  useEffect(() => {
    setSelectedLanguage(getLocale());
  }, []);

  const rerenderCart = () => {
    setSession(getSession());
  };

  const listenScrollEvent = (e) => {
    // if the window is scrolled for more than 5 pixels
    // the topbar will have the className "scrolled" applied
    setScrolled(window.scrollY > 5);
  };

  const getProductsLength = () => {
    let quantity = 0;
    if (!session || !session.products) return 0;
    session.products.forEach((product) => {
      if (product.quantity) quantity += product.quantity;
      product.bundleProducts &&
        product.bundleProducts.forEach((bundle) => {
          if (bundle.quantity) quantity += bundle.quantity;
        });
    });
    return quantity;
  };

  const productsLength = getProductsLength();

  let productsCurrency;
  let productsTotalPrice = 0;
  session &&
    session.products &&
    session.products.forEach((product) => {
      product.attributeList.forEach((attr) => {
        if (attr.attribute === "unitPrice") {
          productsTotalPrice += parseFloat(attr.value) * product.quantity;
        }
        if (attr.attribute === "currency") {
          productsCurrency = attr.value;
        }
      });
      if (product.bundleSettings && product.bundleSettings.active) {
        if (product.bundleProducts) {
          product.bundleProducts.forEach((element) => {
            if (element.quantity > 0) {
              productsTotalPrice +=
                parseFloat(element.unitPrice) * element.quantity;
            }
          });
        }
      }
    });

  const openLoginModal = () => {
    const session = getSession();
    if (session && session.customer) return navigate("/my-account");
    setLoginModal(true);
  };

  const closeLoginModal = () => {
    setLoginModal(false);
  };

  const logged = session && session.customer;

  return {
    config,
    scrolled,
    showLanguages,
    showCart,
    showProfile,
    productsCurrency,
    productsTotalPrice,
    productsLength,
    logo,
    loginModal,
    openLoginModal,
    closeLoginModal,
    logged,
    defaultEmail,
    selectedLanguage,
    mainMenuOpen,
    setMainMenuOpen,
    languagesMenuOpen,
    setLanguagesMenuOpen,
  };
};

export default useTopbar;
