import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface ImageProps {
  image: any;
  type: "small" | "medium" | "large" | "thumbnail";
  alt: string;
  className?: string;
  imgClassName?: string;
  as?: string;
  loading?: "eager" | "lazy";
  imgStyle?: any;
  style?: any;
  noFluid?: boolean;
}

function Image({
  image,
  type = "medium",
  alt,
  className,
  imgClassName,
  as = "div",
  loading = "lazy",
  imgStyle = {},
  style = {},
  noFluid = false,
}: ImageProps) {
  if (!image) {
    return null;
  }
  let { allFile } = useStaticQuery(graphql`
    {
      allFile {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                width: 1024
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  let fluidImage = null;
  allFile.edges.forEach((element) => {
    let compField = false;
    if (image.localFile___NODE) {
      compField = image.localFile___NODE;
    }
    if (image.localFile && image.localFile.id) {
      compField = image.localFile.id;
    }
    if (element.node.id === compField) {
      fluidImage = getImage(element.node.childImageSharp);
      return;
    }
  });

  if (fluidImage && !noFluid) {
    return (
      <GatsbyImage
        image={fluidImage}
        alt={alt}
        className={className}
        imgStyle={imgStyle}
        style={style}
        loading={loading}
      />
    );
  }

  if (
    image &&
    image.formats &&
    image.formats[type] &&
    image.formats[type].url
  ) {
    return (
      <img
        style={style}
        src={image.formats[type].url}
        alt={alt}
        className={className}
      />
    );
  }

  return null;
}

export default Image;
