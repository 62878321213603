import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";

const CrossMenu = ({ size, isMenuActive }) => {
  const [load, setLoad] = useState(true);
  const NewLine = () => {
    return (
      <svg
        width={"100%"}
        height={"100%"}
        viewBox={`0 0 20 2`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 1H1"
          stroke="#242426"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  useEffect(() => {
    if (load && isMenuActive === true) setLoad(false);
  }, [isMenuActive]);
  return (
    <span
      className={`${styles.menuAnimation} ${
        isMenuActive ? styles.isActive : ""
      } ${load ? styles.load : ""}`}
      style={{ width: size, height: size }}
    >
      <span className={`${styles.lineWrapper} `}>
        <NewLine />
      </span>
      <span className={`${styles.lineWrapper} `}>
        <NewLine />
      </span>
      <span className={`${styles.lineWrapper} `}>
        <NewLine />
      </span>
    </span>
  );
};
export default CrossMenu;
