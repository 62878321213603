import { Chars, Templates, CurrencyPosition } from "./types";
import { CurrencyCodes, CurrencyTemplates } from "./data";

const parseCurrencyCodetoSign = (currency: string) =>
  CurrencyCodes[currency.toLowerCase()];

const getDecimalPart = (value: number | string) => {
  const decimalPart = parseFloat(value).toFixed(2);
  const decimalChar = getDecimalChar();
  return `${decimalChar}${decimalPart.toString().split(".").pop()}`;
};

const prepareIntegerPart = (value: number, char: Chars) => {
  // remove decimals
  let intPart: string = Math.trunc(value).toString();
  // add a char before three chars
  if (char) intPart = intPart.replace(/\d(?=(?:\d{3})+$)/g, `$&${char}`);
  return intPart;
};

const getTemplate = (template: Templates, position: CurrencyPosition) => {
  if (position === "left") {
    const fixedTemplate = { ...CurrencyTemplates[template] };
    if (!fixedTemplate.decimalVariant) {
      fixedTemplate.decimalVariant = fixedTemplate.integerVariant;
    }
    return fixedTemplate;
  }
  return CurrencyTemplates[template];
};

const shouldDecimalsBeDisplayed = (displayDecimals) => {
  if (typeof displayDecimals !== "undefined") return displayDecimals;
  return getCurrencyDecimals();
};

const getCurrencyChar = () => {
  const char: Chars = ".";
  return char;
};

const getDecimalChar = () => {
  const char: Chars = ",";
  return char;
};

const getCurrencyPosition = () => {
  return "right";
};

const getCurrencyDecimals = () => {
  return false;
};

export {
  parseCurrencyCodetoSign,
  getDecimalPart,
  prepareIntegerPart,
  getTemplate,
  getCurrencyChar,
  getCurrencyPosition,
  shouldDecimalsBeDisplayed,
};
