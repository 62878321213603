// extracted by mini-css-extract-plugin
export var topbar = "styles-module--topbar--28LZh";
export var scrolled = "styles-module--scrolled--BBpqQ";
export var topbarContainer = "styles-module--topbarContainer--2pCcS";
export var logoLink = "styles-module--logoLink--1rctS";
export var category = "styles-module--category--3qMYy";
export var widgets = "styles-module--widgets--K7XnA";
export var menuWrapper = "styles-module--menuWrapper--20gwy";
export var menu = "styles-module--menu--1wFZ2";
export var categoryTitle = "styles-module--categoryTitle--eSuXJ";
export var select = "styles-module--select--3-fqk";
export var userWrapper = "styles-module--userWrapper--2jO2P";
export var user = "styles-module--user--1z8sy";
export var check = "styles-module--check--2Gzgl";
export var shoppingCartWrapper = "styles-module--shoppingCartWrapper--36O6H";
export var shoppingCartContent = "styles-module--shoppingCartContent--ZqBdy";
export var shoppingCart = "styles-module--shoppingCart--2UA73";
export var cartIcon = "styles-module--cartIcon--3M0LL";
export var badge = "styles-module--badge--1YPvn";
export var bundlePrice = "styles-module--bundlePrice--1Aunr";
export var language = "styles-module--language--2Lq82";
export var selectedLanguage = "styles-module--selectedLanguage--2X4wQ";
export var languageWrapper = "styles-module--languageWrapper--29y66";
export var logo = "styles-module--logo--1wbB2";
export var widget = "styles-module--widget--1R4Is";