import React, { ElementType } from "react";
import { Typography as TypographyMUI } from "@material-ui/core";

/* 
    la prop "component" define el elemento html que se invocará
    la prop "variant" define el className de MaterialUI que se asignará
*/

import "./index.scss";
interface TypographyProps {
  variant: Variant;
  component: ElementType<any>;
  className?: string;
  href?: string;
  target?: string;
  key?: string;
  disabled?: boolean;
  onClick?: Function;
}

type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline";

export default class Typography extends React.PureComponent<TypographyProps> {
  render() {
    const { variant, component, className, href, target, disabled, onClick } =
      this.props;
    return (
      <TypographyMUI
        onClick={onClick}
        disabled={disabled}
        target={target}
        href={href}
        className={className}
        variant={variant}
        component={component}
      >
        {this.props.children}
      </TypographyMUI>
    );
  }
}
