import React from "react";
import Link from "../link";
import * as styles from "./styles.module.scss";

interface CategoryMenuProps {
  menus?: Array<Menu>;
  visible: boolean;
  type: string;
  key?: number;
  children?: any;
  setVisible?: any;
}

type Menu = {
  title: string;
  href: string;
  target: string;
  items: Array<LinkType>;
};

type LinkType = {
  label: string;
  href: string;
  target?: "_self" | "_blank";
};

const CategoryMenu = (props: CategoryMenuProps) => {
  const { menus, visible, type, setVisible } = props;

  const getMenuContent = () => {
    switch (type) {
      case "menu":
        if (!menus) return null;
        return (
          <>
            <div className={styles.languagePicker}>{props.children}</div>
            {menus.map((menu, index) => {
              return (
                <React.Fragment key={`menu_${index}`}>
                  <div className={styles.menu}>
                    <Link
                      title={menu.title}
                      className={`${styles.menuTitle} isLink`}
                      url={menu.href}
                      target={menu.target}
                      onClick={() => setVisible && setVisible(false)}
                    >
                      {menu.title}
                    </Link>
                    <div className={styles.links}>
                      {menu.items.map((link, idx) => {
                        return (
                          <React.Fragment key={`link_${index}${idx}`}>
                            <Link
                              title={link.title || link.label}
                              className={`${styles.link} isLink`}
                              url={link.href}
                              target={link.target}
                              onClick={() => setVisible && setVisible(false)}
                            >
                              {link.label}
                            </Link>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </>
        );
      case "language":
      case "snowconditions":
      case "weather":
        return props.children;
      default:
        return null;
    }
  };

  return (
    <div
      className={`${styles.accordion} ${styles[type]} ${
        visible && styles.open
      } menuDropdown`}
    >
      <div className={styles.dropdown}>{getMenuContent()}</div>
    </div>
  );
};

export default CategoryMenu;
