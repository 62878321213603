import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";

import Helmet from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";

import { Metatags } from "./metatags";
import Topbar from "../../modules/topbar";
import Footer from "../../modules/footer2";

import {
  getSession,
  setSession,
  getInAppPurchase,
  setInAppPurchase,
} from "../../utils/localStorage";
import { size } from "../../constants";
import QueryStringRetriever from "../../utils/queryStringRetriever";
import { CONNECT, CONNECT_CLIENT } from "../../utils/definitions";

import "./index.scss";
import { getMainConfig } from "../../utils/getConfig";

const Main = styled.main.attrs((props) => ({}))`
  background: ${(props) => props.backgroundColor};
  ${(props) =>
    props.backgroundColorDesktop &&
    `@media only screen and (min-width: ${size.laptop}px) {
        background: ${props.backgroundColorDesktop};
      }`}
`;

const Layout = ({
  children,
  type = "default",
  metatags = {},
  header,
  maxWidth = "lg",
  hideMobileFooter = false,
  data,
  backgroundColor,
  backgroundColorDesktop,
}) => {
  const [hideTopbarFooter, setHideTopbarFooter] = useState(false);
  let { scripts = [], theme = {}, favicons = [] } = getMainConfig();

  const hexToHsl = (hex, saturation = 0) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);

    r /= 255;
    g /= 255;
    b /= 255;
    let max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          break;
      }
      h /= 6;
    }

    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    h = Math.round(360 * h);

    s = saturateHsl(s, saturation);

    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  };

  const saturateHsl = (saturation, saturationAdd) => {
    let totalSat = saturation + saturationAdd;
    if (totalSat > 100) {
      return 100;
    }
    if (totalSat < 0) {
      return 0;
    }
    return totalSat;
  };

  useEffect(() => {
    let {
      background,
      primaryColor,
      secondaryColor,
      primaryButton,
      secondaryButton,
      textColor,
    } = theme;

    if (typeof primaryColor === "undefined") {
      background = "";
      primaryColor = "#3595ba";
      secondaryColor = "#006cd1";
      primaryButton =
        "linear-gradient(to bottom,#ffaf18 0,#ffaf18 50%,#fba504 50%,#fba504 100%)";
      secondaryButton =
        "linear-gradient(to top,#0060b9 0,#0060b9 50%,#006cd1 50%,#006cd1 100%)";
      textColor = "#333333";
    }

    const highlightedBtnColor = hexToHsl(primaryColor, 20);
    document.documentElement.style.setProperty("--background", background);
    document.documentElement.style.setProperty("--primaryColor", primaryColor);
    document.documentElement.style.setProperty(
      "--highlighted-btn-color",
      highlightedBtnColor
    );
    document.documentElement.style.setProperty(
      "--secondaryColor",
      secondaryColor
    );
    document.documentElement.style.setProperty(
      "--primaryButtonColor",
      primaryButton.color
    );
    document.documentElement.style.setProperty(
      "--primaryButtonBackground",
      primaryButton.background
    );
    document.documentElement.style.setProperty(
      "--secondaryButtonColor",
      secondaryButton.color
    );
    document.documentElement.style.setProperty("--textColor", textColor);
    document.documentElement.style.setProperty(
      "--secondaryButtonBackground",
      secondaryButton.background
    );
    // eslint-disable-next-line
  }, [theme]);

  useEffect(() => {
    const updateViewportHeight = () => {
      let vh = window.innerHeight * 0.01;
      let vwh =
        window && window.visualViewport && window.visualViewport.height
          ? window.visualViewport.height
          : window.innerHeight;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      document.documentElement.style.setProperty("--vwh", `${vwh}px`);
    };
    updateViewportHeight();
    window.addEventListener("resize", updateViewportHeight);
  });

  useEffect(() => {
    const QS = QueryStringRetriever;
    // boolean that hides topbar & footer if true
    if (QS().inAppPurchase || getInAppPurchase()) {
      setHideTopbarFooter(true);
      if (QS().inAppPurchase) {
        setInAppPurchase(true);
      }
    }
    checkForSession();
    // eslint-disable-next-line
  }, []);

  const checkDevice = () => {
    const checkOS = () => {
      const ua = navigator.userAgent;

      console.log("user agent", ua, navigator.maxTouchPoints);

      if (/android/i.test(ua)) {
        return (userAgent.isAndroid = true);
      } else if (/iPad|iPhone|iPod/.test(ua) || navigator.maxTouchPoints > 1) {
        userAgent.isIos = true;
      }
      return "Other";
    };

    let userAgent = {
      isIos: false,
      isAndroid: false,
      isDesktop: false,
      isMobile: false,
      device: "",
      screenRes: {
        width: 0,
        height: 0,
      },
    };

    const isMobile =
      window.matchMedia("only screen and (max-width: 760px)").matches &&
      "ontouchstart" in document.documentElement &&
      navigator.userAgent.match(/Mobi/);

    if (isMobile) {
      userAgent.isMobile = true;
      checkOS();
    } else {
      userAgent.isDesktop = true;
    }

    const ua = navigator.userAgent;

    if (ua.match(/chrome|chromium|crios/i)) {
      userAgent.device = "chrome";
    } else if (ua.match(/firefox|fxios/i)) {
      userAgent.device = "firefox";
    } else if (ua.match(/safari/i)) {
      userAgent.device = "safari";
    } else if (ua.match(/opr\//i)) {
      userAgent.device = "opera";
    } else if (ua.match(/edg/i)) {
      userAgent.device = "edge";
    } else {
      userAgent.device = "other";
    }

    userAgent.screenRes.width = window.screen.availWidth;
    userAgent.screenRes.height = window.screen.availHeight;

    return userAgent;
  };

  const checkForSession = () => {
    const session = getSession();
    const userAgent = checkDevice();

    if (
      !session ||
      (session && !session.uuid) ||
      (session &&
        moment
          .duration(moment.utc().diff(moment.utc(session.updated_at)))
          .asHours() >= 6)
    ) {
      axios
        .post(`${CONNECT}/checkout/session`, {
          client: CONNECT_CLIENT,
          session: "",
          userAgent: userAgent,
        })
        .then((response) => {
          if (response && response.data && response.data.uuid) {
            response.data.timeStamp = new Date().getTime();

            setSession(response.data);
          }
        });
    }
  };

  let globalScripts = [];
  if (scripts) {
    scripts.forEach((script) => {
      let scr = {
        type: "text/javascript",
        charset: "UTF-8",
      };

      if (script.identifier) {
        scr.id = script.identifier;
      }

      if (script.src && script.src !== "#") {
        scr.src = script.src;
      }

      if (script.innerHTML && script.innerHTML !== "#") {
        scr.innerHTML = script.innerHTML;
      }

      if (script.mode !== "normal") {
        scr[script.mode] = "";
      }

      globalScripts.push(scr);
    });
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Metatags
        favicons={favicons}
        background={theme.background}
        data={metatags}
      />
      <Helmet script={globalScripts}>
        <style>
          {`
              html {
                background-color: ${backgroundColor}
              }
              @media only screen and (min-width: ${size.laptop}px) {
                html {
                  background-color: unset;
                }
              }
            `}
        </style>
      </Helmet>
      {!hideTopbarFooter && <Topbar />}
      <Main
        backgroundColor={backgroundColor}
        backgroundColorDesktop={backgroundColorDesktop}
      >
        {children}
      </Main>
      {!hideTopbarFooter && <Footer hideMobileFooter={hideMobileFooter} />}
    </React.Fragment>
  );
};

export default Layout;
