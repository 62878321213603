import React from "react";
import { Link as GatsbyLink } from "gatsby";

interface LinkProps {
  url: string;
  className?: string;
  children: any;
  state?: any;
  target?: string;
  title?: string;
  linkRef?: any;
  onClick?: any;
}

const Link = (props: LinkProps) => {
  const {
    url,
    className,
    children,
    state,
    target,
    title = "",
    linkRef,
  } = props;

  const type = () => {
    if (url.indexOf("https://") >= 0 || url.indexOf("http://") >= 0) {
      return "external";
    }
    return "internal";
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  switch (type()) {
    case "internal":
      return (
        <GatsbyLink
          onClick={(e) => handleClick(e)}
          className={className}
          target={target}
          state={state ? state : null}
          to={url}
          title={title}
          ref={linkRef}
        >
          {children}
        </GatsbyLink>
      );
    case "external":
    default:
      return (
        <a
          onClick={(e) => handleClick(e)}
          ref={linkRef}
          title={title}
          href={url}
          rel="noopener noreferrer"
          target={target}
          className={className}
        >
          {children}
        </a>
      );
  }
};

export default Link;
