import React from "react";

import Typography from "../typography";

import { setLocale } from "../../utils/localStorage";
import { LANGUAGES } from "../../utils/definitions";
import { LocaleAsString } from "../../localeEngine";

import * as styles from "./styles.module.scss";

interface LanguageWidgetProps {
  selectedLanguage: string;
}

const LanguageWidget = (props: LanguageWidgetProps) => {
  const { selectedLanguage } = props;

  const updateLanguage = (language) => {
    setLocale(language);
    window.location.href = "/";
  };

  return (
    <div className={styles.languages}>
      <Typography
        className={styles.languageTitle}
        component="p"
        variant="body1"
      >
        <LocaleAsString id="general.languages.title" />
      </Typography>
      <div className={styles.options}>
        {LANGUAGES.split(",").map((language, index) => {
          const lang = language.toLowerCase();
          return (
            <p key={index} onClick={() => updateLanguage(lang)}>
              <Typography
                variant="body2"
                component="span"
                className={`${styles.language} ${
                  selectedLanguage === lang ? styles.selectedLanguage : null
                }`}
              >
                <LocaleAsString id={`general.languages.${language}`} />
              </Typography>
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default LanguageWidget;
