import React from "react";
import { Helmet } from "react-helmet";

import * as styles from "./styles.module.scss";

const Metatags = ({ data, background, favicons }) => {
  const lang = "no";

  return (
    <Helmet titleTemplate={data.title} defaultTitle={data.title} defer={false}>
      <html lang={lang} amp />
      <body className={styles.root} />
      <title itemProp="name" lang={lang}>
        {data.title}
      </title>

      {favicons.map((favicon, idx) => {
        return (
          <link
            key={`layout_favicon_${idx}`}
            rel={favicon.rel}
            href={favicon.href}
            type={favicon.type !== "#" ? favicon.type : ""}
            sizes={favicon.size !== "#" ? favicon.size : ""}
          />
        );
      })}

      {background ? (
        <style>{`body { background-image: url("${background}"); }`}</style>
      ) : null}

      <meta name="description" content={data.description} />
      <meta name="keywords" content={data.keyword} />
      <meta name="robots" content={data.robots} />
    </Helmet>
  );
};

export { Metatags };
