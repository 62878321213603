import React, { useEffect, useState } from "react";

import SpotlioSocket from "../../components/spotlioSocket";
import { Container, Typography } from "../../components/utils";
import Link from "../../components/link";
import Image from "../../components/image";

import { getCurrentYear } from "../../utils/dateUtils";
import { getLocale } from "../../utils/localStorage";

import * as styles from "./styles.module.scss";
import { getFooterConfig } from "../../utils/getConfig";

interface FooterProps {
  config: {
    logo: string;
    copyright: string;
  };
  hideMobileFooter?: boolean;
}

const Footer = (props: FooterProps) => {
  const [loading, setLoading] = useState(true);
  const [footerData, setFooterData] = useState();
  const config = getFooterConfig({ locale: getLocale() });

  useEffect(() => {
    setTimeout(() => {
      setFooterData(config);
      setLoading(false);
    }, 75);
  }, []);

  // TODO: spotlio icons
  const icons = {
    email: "https://ik.imagekit.io/spotlio/bjorli/icons/email_1Xo_PNv40.png",
    phone: "https://ik.imagekit.io/spotlio/bjorli/icons/phone_SbREAe3ajtO.png",
    pin: "https://ik.imagekit.io/spotlio/bjorli/icons/pin_z75wKtbAs.png",
  };

  let globalScripts = [];
  if (config.scripts) {
    config.scripts.forEach((script) => {
      if (script.innerHTML !== "#") {
        globalScripts.push(script.innerHTML);
      }
    });
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <footer
        className={`${styles.pageFooter} ${
          props.hideMobileFooter ? styles.hideMobile : ""
        }`}
      >
        <Container maxWidth="lg" className={styles.footerWrapper}>
          <div className={styles.column1}>
            <ul>
              {footerData &&
                footerData.footerLinks.column1.map((item, index) => {
                  let insideLink = item.label;
                  return (
                    <li key={"col1" + index}>
                      <Link
                        url={item.href}
                        target={item.target}
                        title={item.label}
                      >
                        <Typography component="span" variant="p-medium">
                          {insideLink}
                        </Typography>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className={styles.column2}>
            <ul>
              {footerData &&
                footerData.footerLinks.column2.map((item, index) => {
                  let insideLink = item.label;
                  return (
                    <li key={"col2" + index}>
                      <Link
                        url={item.href}
                        target={item.target}
                        title={insideLink || ""}
                      >
                        <Typography component="span" variant="p-medium">
                          {insideLink}
                        </Typography>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className={styles.column3}>
            {footerData &&
              footerData.footerLinks.column3.map((item, index) => {
                let insideLink = item.label;
                if (item.image && item.image.url) {
                  insideLink = (
                    <img
                      width={35}
                      height={35}
                      src={item.image.url}
                      alt={item.label}
                    />
                  );
                }
                return (
                  <Link
                    type="external"
                    title={insideLink}
                    key={"col3" + index}
                    url={item.href}
                    target={item.target}
                  >
                    <Typography component="span" variant="p-medium">
                      {insideLink}
                    </Typography>
                  </Link>
                );
              })}
          </div>
        </Container>
      </footer>
      <footer
        className={`${styles.clientFooter} ${
          props.hideMobileFooter ? styles.hideMobile : undefined
        }`}
      >
        <Container maxWidth="lg" className={styles.footerWrapper}>
          <div className={styles.column1}>
            <Image
              className={styles.logo}
              type="thumbnail"
              noFluid={false}
              image={footerData && footerData.logo}
              alt={footerData && footerData.logoAlternativeText}
              style={{ width: 87, height: 50 }}
              imgStyle={{ width: 87, height: 50 }}
            />
          </div>
          <div className={styles.column2}>
            <Typography component="span" variant="p-medium">
              &copy;{getCurrentYear()} {footerData && footerData.copyright}
            </Typography>
          </div>
          <div className={styles.column3}>
            <Typography component="span" variant="p-heavy">
              {footerData && footerData.destination.name}
            </Typography>
            <Typography
              component="span"
              variant="p-medium"
              className={styles.line}
            >
              {footerData && footerData.destination.address.street},{" "}
              {footerData && footerData.destination.address.postalCode}{" "}
              {footerData && footerData.destination.address.city}
              <img width={12} height={12} src={icons.pin} alt="map-pin" />
            </Typography>
            <Typography
              component="span"
              variant="p-medium"
              className={styles.line}
            >
              <a
                href={`tel:${
                  footerData && footerData.destination.contact.phonePrefix
                } ${footerData && footerData.destination.contact.phone}`}
                rel="noopener noreferrer"
              >{`${footerData && footerData.destination.contact.phonePrefix} ${
                footerData && footerData.destination.contact.phone
              }`}</a>
              <img width={12} height={12} src={icons.phone} alt="phone" />
            </Typography>
            <Typography
              component="span"
              variant="p-medium"
              className={styles.line}
            >
              <a
                href={`mailto:${
                  footerData && footerData.destination.contact.email
                }`}
                rel="noopener noreferrer"
              >{`${config.destination.contact.email}`}</a>
              <img width={12} height={12} src={icons.email} alt="email" />
            </Typography>
          </div>
        </Container>
      </footer>
      <div className={props.hideMobileFooter ? styles.hideMobile : undefined}>
        <SpotlioSocket />
      </div>
      {globalScripts.length > 0 ? (
        <div dangerouslySetInnerHTML={{ __html: globalScripts.join("\r\n") }} />
      ) : null}
    </>
  );
};

export default Footer;
