import { Variant } from "../../components/utils/typography/types";

interface CurrencyTemplatesValues {
  [key: string]: {
    decimalVariant: Variant;
    integerVariant: Variant;
    currencyVariant?: Variant;
  };
}

interface CurrencyCodes {
  [key: string]: string;
}

const CurrencyCodes: CurrencyCodes = {
  nok: "kr",
  kr: "kr",
  usd: "$",
  eur: "€",
};

const CurrencyTemplates: CurrencyTemplatesValues = {
  checkout: {
    decimalVariant: "h800-heavy",
    integerVariant: "h800-heavy",
  },
  checkoutBundle: {
    integerVariant: "h500-medium",
    decimalVariant: "h500-medium",
  },
  topbar: {
    decimalVariant: "h400-heavy",
    integerVariant: "h400-heavy",
  },
  seasonPassBundleModal: {
    decimalVariant: "p-heavy",
    integerVariant: "h4",
  },
  taxesCheckout: {
    decimalVariant: "h300-medium",
    integerVariant: "h300-medium",
  },
  featuredProductSpotlioTwo: {
    decimalVariant: "p-medium",
    integerVariant: "h3",
  },
  myAccountOrder: {
    decimalVariant: "h500-heavy",
    integerVariant: "h500-heavy",
  },
  myAccountOrderTotal: {
    decimalVariant: "h800-heavy",
    integerVariant: "h800-heavy",
  },
  dailyPassAdded: {
    decimalVariant: "h300-heavy",
    integerVariant: "h300-heavy",
  },
  passholderProductTotal: {
    decimalVariant: "h600-heavy",
    integerVariant: "h600-heavy",
  },
  passholderTotal: {
    decimalVariant: "h400-heavy",
    integerVariant: "h800-heavy",
  },
  lodgingCell: {
    integerVariant: "h700-heavy",
    decimalVariant: "h400-heavy",
    currencyVariant: "h400-heavy",
  },
  lodging: {
    integerVariant: "h500-medium",
    decimalVariant: "h500-medium",
  },
  lodgingSmall: {
    integerVariant: "h300-medium",
    decimalVariant: "h300-medium",
  },
  lodgingMarker: {
    integerVariant: "h300-medium",
    decimalVariant: "h300-medium",
  },
};

export { CurrencyCodes, CurrencyTemplates };
